/* eslint-disable no-undef */
"use strict";

var formValidation = require("base/components/formValidation");
var createErrorNotification = require("base/components/errorNotification");
const dayDropdown = document.getElementById("dobday");
const monthDropdown = document.getElementById("dobmonth");
const yearDropdown = document.getElementById("dobyear");


// Fetch all years before the current year
const currentYear = new Date().getFullYear();
for (let year = currentYear; year >= 1900; year--) {
    const option = document.createElement("option");
    option.value = year;
    option.text = year;
    if (yearDropdown) yearDropdown.appendChild(option);
}

// Populate days dropdown by default
updateDays();

function updateDays() {
    if (monthDropdown == null || yearDropdown == null || dayDropdown == null) return false;
    const selectedMonth = monthDropdown.value || new Date().getMonth() + 1; // Default to current month if not selected
    const selectedYear = yearDropdown.value || currentYear; // Default to current year if not selected

    if (isNaN(selectedMonth) || isNaN(selectedYear)) {
        // Handle invalid month or year values
        console.error("Invalid month or year");
        return;
    }
    const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();
    dayDropdown.innerHTML = "<option value=\"\">Day</option>";

    for (let i = 1; i <= daysInMonth; i++) {
        const option = document.createElement("option");
        option.value = i < 10 ? "0" + i : i;
        option.text = i;
        dayDropdown.appendChild(option);
    }
}

module.exports = {
    login: function () {
        $("form.login").submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr("action");
            form.spinner().start();
            $("form.login").trigger("login:submit", e);
            $.ajax({
                url: url,
                type: "post",
                dataType: "json",
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                        $("form.login").trigger("login:error", data);
                    } else {
                        $("form.login").trigger("login:success", data);
                        location.href = data.redirectUrl;
                    }
                },
                error: function (data) {
                    if (data.responseJSON.redirectUrl) {
                        window.location.href = data.responseJSON.redirectUrl;
                    } else {
                        $("form.login").trigger("login:error", data);
                        form.spinner().stop();
                    }
                }
            });
            return false;
        });
    },

    register: function () {
        $("form.registration").submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr("action");
            form.spinner().start();
            $("form.registration").trigger("login:register", e);
            $.ajax({
                url: url,
                type: "post",
                dataType: "json",
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        $("form.registration").trigger("login:register:error", data);
                        formValidation(form, data);
                    } else {
                        $("form.registration").trigger("login:register:success", data);
                        location.href = data.redirectUrl;
                    }
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification($(".error-messaging"), err.responseJSON.errorMessage);
                    }

                    form.spinner().stop();
                }
            });
            return false;
        });
    },

    resetPassword: function () {
        $(".reset-password-form").submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr("action");
            form.spinner().start();
            $(".reset-password-form").trigger("login:register", e);
            $.ajax({
                url: url,
                type: "post",
                dataType: "json",
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                    } else {
                        $(".request-password-title").text(data.receivedMsgHeading);
                        $(".request-password-body").empty()
                            .append("<p>" + data.receivedMsgBody + "</p>");
                        if (!data.mobile) {
                            $("#submitEmailButton").text(data.buttonText)
                                .attr("data-dismiss", "modal");
                        } else {
                            $(".send-email-btn").empty()
                                .html("<a href=\""
                                    + data.returnUrl
                                    + "\" class=\"btn btn-primary btn-block\">"
                                    + data.buttonText + "</a>"
                                );
                        }
                    }
                },
                error: function () {
                    form.spinner().stop();
                }
            });
            return false;
        });
    },

    clearResetForm: function () {
        $("#login .modal").on("hidden.bs.modal", function () {
            $("#reset-password-email").val("");
            $(".modal-dialog .form-control.is-invalid").removeClass("is-invalid");
        });
    }
};
